import { ThemeType } from "../../models/enums/theme.enum";
import { MenuEntry } from "../../../modules/protected/components/menu/models/menu.model";
import { TecComModuleType } from "shared-states";

export interface ShellStateModel {
  defaultMenu: MenuEntry[];
  userMenu: MenuEntry[];
  theme: ThemeType;
  isInitialized: boolean;
  activeModule?: TecComModuleType;
  error?: Error;
}
