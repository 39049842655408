<mat-toolbar class="footer">
  <button mat-stroked-button color="accent" (click)="contactSupport()">
    <mat-icon fontSet="ta-icons" fontIcon="help-circle"></mat-icon>
    {{ 'Shell.Footer.Support' | translate }}
  </button>
  <button mat-stroked-button color="accent" (click)="navigateTo(tecComWikiUri, true)">
    <mat-icon fontSet="ta-icons" fontIcon="book-open-01"></mat-icon>
    {{ 'Shell.Footer.Wiki' | translate }}
  </button>
  <button mat-button class="hide-large" color="accent" (click)="navigateTo(legacyWebinarUri, true)">
    {{ 'Shell.Footer.Webinars' | translate }}
  </button>
  <button mat-button class="hide-large" color="accent" (click)="navigateTo(legacyReleaseNotesUri, true)">
    {{ 'Shell.Footer.ReleaseNotes' | translate }}
  </button>
  <button mat-button color="accent" (click)="navigateTo(statusPageUri, true)">
    {{ 'Shell.Footer.SystemStatus' | translate }}
  </button>
  <button mat-button class="hide-large" color="accent" (click)="navigateTo(legacyLegalNotice, true)">
    {{ 'Shell.Footer.LegalNotice' | translate }}
  </button>
  <button mat-button color="accent" (click)="navigateTo(tecallianceWebSite, true)">
    {{ 'Shell.Footer.Website' | translate }}
  </button>
  <button mat-button color="accent" (click)="openCookieSettings()">
    {{ 'Shell.Footer.CookieSettings' | translate }}
  </button>
  <span class="spacer"></span>
  <button mat-button color="accent" tsc-beta [matMenuTriggerFor]="themeMenu">
    <mat-icon fontSet="ta-icons" fontIcon="circle-cut"></mat-icon>
    {{ 'Shell.Footer.Theme.' + (theme$ | async) | translate }}
    <span class="tsc-badge">{{ 'Shell.Common.Beta' | translate }}</span>
  </button>
  <mat-menu #themeMenu="matMenu">
    <button mat-menu-item *ngFor="let theme of themeType | keyvalue" (click)="setTheme(theme.value)">
      {{ 'Shell.Footer.Theme.' + theme.value | translate }}
    </button>
  </mat-menu>
  <div data-test-id="languageSelector">
    <button mat-button color="accent" [matMenuTriggerFor]="langMenu">
      <mat-icon fontSet="ta-icons" fontIcon="globe-06"></mat-icon>
      {{ (currentLanguageItem$ | async)?.label! | translate }}
    </button>
    <mat-menu class="language-menu" #langMenu="matMenu" xPosition="before">
      <button mat-menu-item *ngFor="let language of (language$ | async)?.supported"
        (click)="languageChanged(language.value)">
        <img class="language-flag" [src]="language.flag" alt="flag" />
        {{ language.label | translate }}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>