import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { LanguageEntry, LanguageService, LanguageState, LanguageStateModel, UserState, UserStateModel } from 'shared-states';
import { ThemeType } from 'src/app/core/models/enums/theme.enum';
import { ShellState, Shell } from 'src/app/core/states';
import { environment } from 'src/environments/environment';
import { CookieStorageService } from '../../services/cookie-storage.service';
import { FooterService } from "../services/footer.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  @Select(LanguageState) language$!: Observable<LanguageStateModel>;
  @Select(LanguageState.getCurrentLanguageItem) currentLanguageItem$!: Observable<LanguageEntry>;
  @Select(ShellState.getTheme) theme$!: Observable<string>;

  themeType = ThemeType;

  tecComWikiUri = environment.tecComWikiUri;
  statusPageUri = environment.statusPageUri;
  tecallianceWebSite = environment.tecallianceWebSite;
  legacyWebinarUri = environment.legacyWebinarUri;
  legacyReleaseNotesUri = environment.legacyReleaseNotesUri;
  legacyLegalNotice = environment.legacyLegalNotice;

  private _unsubscribe = new Subject<void>();

  constructor(@Inject(DOCUMENT) public document: any,
    public r: Renderer2,
    private _languageService: LanguageService,
    private _storageService: CookieStorageService,
    private _store: Store,
    private _footerService: FooterService,) { }

  ngOnInit(): void {
    if (!this._storageService.get(this._footerService.COOKIE_STATUS_KEY)) {
      this.openCookieSettings();
    }
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  setTheme(theme: ThemeType) {
    if (this._store.selectSnapshot(ShellState.getTheme) !== theme) {
      this.r.removeAttribute(document.body, 'class');
      this.r.addClass(document.body, `ta-${theme.toLowerCase()}-theme`);
      this._store.dispatch(new Shell.ChangeThemeSuccessAction(theme));
    }
  }

  languageChanged(lang: string) {
    if (this._store.selectSnapshot(LanguageState.getCurrentLanguageItem)?.value !== lang) {
      this._storageService.set('Language',lang);
      this._languageService.use(lang);
    }
  }

  navigateTo(uri: string, targetBlank?: boolean) {
    if (!targetBlank) {
      window.location.href = uri;
    } else {
      window.open(uri, '_blank');
    }
  }

  contactSupport() {
    this._footerService.contactSupport();
  }

  openCookieSettings() {
    this._footerService.openCookieSettings();
  }
}
