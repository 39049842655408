import { Directive, ElementRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[tsc-beta]'
})
export class TscBetaDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (environment.production) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
